<script setup>

</script>

<template>
  <div id="head_div">
    <h1>ayanami's blog</h1>
  </div>
</template>

<style scoped>
#head_div{
  text-align: center;
  position: fixed;
  background: black;
  width: 100%;
  top: 0;
  z-index: 99;
}
#search_input{
  position: fixed;
}
</style>