import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('../App.vue'),
        // children: [
        //     {
        //         path: '/detail',
        //         name: 'articleDetail',
        //         component: () => import('../page/ArticleDetail.vue')
        //     }
        // ]
    },
    {
        path: '/detail/:id',
        name: 'articleDetail',
        component: () => import('../page/ArticleDetail.vue')
    },
    {
        path: '/save/:id',
        name: 'articleSaveById',
        component: () => import('../page/ArticleSave.vue')
    },
    {
        path: '/save',
        name: 'articleSave',
        component: () => import('../page/ArticleSave.vue')
    },
    {
        path: '/list',
        name: 'articleList',
        component: () => import('../page/ArticleList.vue')
    },
    {
        path: '/adminLogin',
        name: 'adminLogin',
        component: () => import('../page/AdminLogin.vue')
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
