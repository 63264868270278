<template>
  <BlogHead/>
  <div id="page">
  <router-view></router-view>
  </div>
</template>

<script>
import BlogHead from "@/components/BlogHead.vue";
import {onMounted} from "vue";
import router from "@/router";
export default {
  name: 'App',
  components: {
    BlogHead
  },
  setup(){
    onMounted(()=>{
      if (window.location.pathname.indexOf("bsx6") > 0) {
          router.push({name:"adminLogin"})
      }else if (window.location.pathname === '/') {
        router.push({name:"articleList"})
      }
    })
  }
}

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  margin-top: 7%;
}

</style>
